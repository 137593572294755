/* #### Generated By: http://www.cufonfonts.com #### */

@font-face {
  font-family: "Montserrat Regular";
  font-style: normal;
  font-weight: normal;
  src: local("Montserrat Regular"),
    url("Montserrat-Regular.woff") format("woff");
}

@font-face {
  font-family: "Montserrat Italic";
  font-style: normal;
  font-weight: normal;
  src: local("Montserrat Italic"), url("Montserrat-Italic.woff") format("woff");
}

@font-face {
  font-family: "Montserrat Thin";
  font-style: normal;
  font-weight: normal;
  src: local("Montserrat Thin"), url("Montserrat-Thin.woff") format("woff");
}

@font-face {
  font-family: "Montserrat Thin Italic";
  font-style: normal;
  font-weight: normal;
  src: local("Montserrat Thin Italic"),
    url("Montserrat-ThinItalic.woff") format("woff");
}

@font-face {
  font-family: "Montserrat ExtraLight";
  font-style: normal;
  font-weight: normal;
  src: local("Montserrat ExtraLight"),
    url("Montserrat-ExtraLight.woff") format("woff");
}

@font-face {
  font-family: "Montserrat ExtraLight Italic";
  font-style: normal;
  font-weight: normal;
  src: local("Montserrat ExtraLight Italic"),
    url("Montserrat-ExtraLightItalic.woff") format("woff");
}

@font-face {
  font-family: "Montserrat Light";
  font-style: normal;
  font-weight: normal;
  src: local("Montserrat Light"), url("Montserrat-Light.woff") format("woff");
}

@font-face {
  font-family: "Montserrat Light Italic";
  font-style: normal;
  font-weight: normal;
  src: local("Montserrat Light Italic"),
    url("Montserrat-LightItalic.woff") format("woff");
}

@font-face {
  font-family: "Montserrat Medium";
  font-style: normal;
  font-weight: normal;
  src: local("Montserrat Medium"), url("Montserrat-Medium.woff") format("woff");
}

@font-face {
  font-family: "Montserrat Medium Italic";
  font-style: normal;
  font-weight: normal;
  src: local("Montserrat Medium Italic"),
    url("Montserrat-MediumItalic.woff") format("woff");
}

@font-face {
  font-family: "Montserrat SemiBold";
  font-style: normal;
  font-weight: normal;
  src: local("Montserrat SemiBold"),
    url("Montserrat-SemiBold.woff") format("woff");
}

@font-face {
  font-family: "Montserrat SemiBold Italic";
  font-style: normal;
  font-weight: normal;
  src: local("Montserrat SemiBold Italic"),
    url("Montserrat-SemiBoldItalic.woff") format("woff");
}

@font-face {
  font-family: "Montserrat Bold";
  font-style: normal;
  font-weight: normal;
  src: local("Montserrat Bold"), url("Montserrat-Bold.woff") format("woff");
}

@font-face {
  font-family: "Montserrat Bold Italic";
  font-style: normal;
  font-weight: normal;
  src: local("Montserrat Bold Italic"),
    url("Montserrat-BoldItalic.woff") format("woff");
}

@font-face {
  font-family: "Montserrat ExtraBold";
  font-style: normal;
  font-weight: normal;
  src: local("Montserrat ExtraBold"),
    url("Montserrat-ExtraBold.woff") format("woff");
}

@font-face {
  font-family: "Montserrat ExtraBold Italic";
  font-style: normal;
  font-weight: normal;
  src: local("Montserrat ExtraBold Italic"),
    url("Montserrat-ExtraBoldItalic.woff") format("woff");
}

@font-face {
  font-family: "Montserrat Black";
  font-style: normal;
  font-weight: normal;
  src: local("Montserrat Black"), url("Montserrat-Black.woff") format("woff");
}

@font-face {
  font-family: "Montserrat Black Italic";
  font-style: normal;
  font-weight: normal;
  src: local("Montserrat Black Italic"),
    url("Montserrat-BlackItalic.woff") format("woff");
}


.montserrat-100 {
  font-family: "Montserrat Thin";
}

.montserrat-100-i {
  font-family: "Montserrat Thin Italic";
}

.montserrat-200 {
  font-family: "Montserrat Light";
}

.montserrat-200-i {
  font-family: "Montserrat Light Italic";
}

.montserrat-300 {
  font-family: "Montserrat ExtraLight";
}

.montserrat-300-i {
  font-family: "Montserrat ExtraLight Italic";
}

.montserrat {
  font-family: "Montserrat Regular" !important;
}

.montserrat-i {
  font-family: "Montserrat Regular Italic";
}

.montserrat-500 {
  font-family: "Montserrat Medium";
}

.montserrat-500-i {
  font-family: "Montserrat Medium Italic";
}

.montserrat-600 {
  font-family: "Montserrat SemiBold";
}

.montserrat-600-i {
  font-family: "Montserrat SemiBold Italic";
}

.montserrat-700 {
  font-family: "Montserrat Bold" !important;
}

.montserrat-700-i {
  font-family: "Montserrat Bold Italic";
}

.montserrat-800 {
  font-family: "Montserrat ExtraBold";
}

.montserrat-800-i {
  font-family: "Montserrat ExtraBold Italic";
}

.montserrat-900 {
  font-family: "Montserrat Black";
}

.montserrat-900-i {
  font-family: "Montserrat Black Italic";
}

