// bootstrap override
$border-radius: 0px;
$primary: #007A42;
$secondary: #00A85A;
$light: #fff;

@import "node_modules/bootstrap/scss/bootstrap";

@import url("https://fonts.googleapis.com/css2?family=Nunito:ital,wght@0,200;0,300;0,400;0,700;0,800;0,900;1,200;1,300;1,400;1,600;1,700;1,800;1,900&display=swap");

@import url("https://fonts.googleapis.com/css2?family=Estonia&display=swap");

@import "assets/fonts/fonts.scss";

.dropdown-menu.show{
  border-radius: 8px;
  display: flex !important;
  flex-direction: column;
  align-items: flex-start;
  padding: 16px;
  gap: 8px;
}

.dropdown-menu a{
  color: #005666;
  font-family: 'Nunito';
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 19px;
}

.btn-main {
  background-color: #00A85A;
  border-color: #00A85A;
  font-size: 14px;
  letter-spacing: 1.25;
  line-height: 140%;
  padding: 14px 40px;
  font-weight: 500;
  border-radius: 10px;
  color: #fff;
  box-shadow: 4px 4px 24px rgba(0, 168, 90, 0.4);
  // --smooth-corners: 20;
  // mask-image: paint(smooth-corners);
  // -webkit-mask-image: paint(smooth-corners);
}

.btn-main h4{
  font-size: inherit;
  margin: 3px;
}

.btn-main-outline {
  border-color: #00A85A;
  font-size: 14px;
  letter-spacing: 1.25;
  line-height: 140%;
  padding: 14px 40px;
  font-weight: 500;
  border-radius: 10px;
  color: #00A85A;
}

.btn-main-outline:hover {
  background-color: #00A85A;
  border-color: #00A85A;
  color:$light;
}

.h-90vh {
  height: 90vh;
}

.ceo-background {
  background-image: url("assets/svgs/ceo-corner/ceo-background.svg");
  background-size: 100% auto;
  background-repeat: no-repeat;
}

.bg-get-in-touch {
  background-color: #02493C !important;
}

.btn-main:hover {
  background-color: #007A42;
  border-color: #007A42;
}

.cursor-pointer{
  cursor: pointer;
}

/* // Small devices (landscape phones, 576px and up) */
@media (min-width: 576px) {
  /* Client Card */
  .btn-main {
    background-color: #00A85A;
    border-color: #00A85A;
    font-size: 14px;
    letter-spacing: 1.25;
    line-height: 140%;
    padding: 14px 40px;
    font-weight: 500;
    border-radius: 10px;
    color: #fff;
  }

  .btn-main-outline {
    border-color: #00A85A;
    font-size: 14px;
    letter-spacing: 1.25;
    line-height: 140%;
    padding: 14px 40px;
    font-weight: 500;
    border-radius: 10px;
    color: #00A85A;
  }
}

/* // Medium devices (tablets, 768px and up) */
@media (min-width: 768px) {
  /* Client Card */
  .btn-main {
    background-color: #00A85A;
    border-color: #00A85A;
    font-size: 14px;
    letter-spacing: 1.25;
    line-height: 140%;
    padding: 14px 40px;
    font-weight: 500;
    border-radius: 10px;
    color: #fff;
  }

  .btn-main-outline {
    border-color: #00A85A;
    font-size: 14px;
    letter-spacing: 1.25;
    line-height: 140%;
    padding: 14px 40px;
    font-weight: 500;
    border-radius: 10px;
    color: #00A85A;
  }

  .btn-main-white {
    background-color: #fff;
    font-size: 14px;
    letter-spacing: 1.25;
    line-height: 140%;
    padding: 14px 40px;
    font-weight: 500;
    border-radius: 10px;
    color: "#000";
  }
}

/* // Large devices (desktops, 992px and up) */
@media (min-width: 992px) {
  /* Client Card */
  .btn-main {
    background-color: #00A85A;
    border-color: #00A85A;
    font-size: 14px;
    letter-spacing: 1.25;
    line-height: 140%;
    padding: 14px 40px;
    font-weight: 500;
    border-radius: 10px;
    color: #fff;
  }

  .btn-main-outline {
    border-color: #00A85A;
    font-size: 14px;
    letter-spacing: 1.25;
    line-height: 140%;
    padding: 14px 40px;
    font-weight: 500;
    border-radius: 10px;
    color: #00A85A;
  }

  .btn-main-white {
    background-color: #fff;
    font-size: 14px;
    letter-spacing: 1.25;
    line-height: 140%;
    padding: 14px 40px;
    font-weight: 500;
    border-radius: 10px;
    color: "#000";
  }
}

/* // Extra large devices (large desktops, 1200px and up) */
@media (min-width: 1200px) {
  /* Client Card */
  .btn-main {
    background-color: #00A85A;
    border-color: #00A85A;
    font-size: 14px;
    letter-spacing: 1.25;
    line-height: 140%;
    padding: 14px 40px;
    font-weight: 500;
    border-radius: 10px;
    color: #fff;
    // --smooth-corners: 20;
    // mask-image: paint(smooth-corners);
    // -webkit-mask-image: paint(smooth-corners);
  }

  .btn-main-outline {
    border-color: #00A85A;
    font-size: 14px;
    letter-spacing: 1.25;
    line-height: 140%;
    padding: 14px 40px;
    font-weight: 500;
    border-radius: 10px;
    color: #00A85A;
  }

  .btn-main-white {
    background-color: #fff;
    font-size: 14px;
    letter-spacing: 1.25;
    line-height: 140%;
    padding: 14px 40px;
    font-weight: 500;
    border-radius: 10px;
    color: "#000";
  }
}

/* // Extra extral large devices (large desktops, 1200px and up) */
@media (min-width: 1900px) {
}
